import { FormSchema } from "@interface48/forms";

export const getCourseFormSchema = (formMode: "read" | "write"): FormSchema => {
  const formSchema: FormSchema = {
    jsonSchema: {
      type: "object",
      title: "",
      required: ["durationMinutes"],
      properties: {
        isExisting: {
          type: "boolean",
        },
        id: {
          type: "string",
        },
        name: {
          type: "string",
          title: "Name",
        },
        durationMinutes: {
          type: "number",
          title: "Duration (Minutes)",
        },
      },
      dependencies: {
        isExisting: {
          oneOf: [
            {
              required: ["file"],
              properties: {
                isExisting: { enum: [false] },
                file: {
                  type: "object",
                  title: "Content (SCORM 1.2)",
                  required: ["fileUploadId", "fileName", "fileSizeBytes"],
                  properties: {
                    fileUploadId: {
                      type: "string",
                    },
                    fileName: {
                      type: "string",
                    },
                    fileSizeBytes: {
                      type: "number",
                    },
                  },
                },
                isFileInvalid: {
                  type: "boolean",
                },
              },
            },
            {
              properties: {
                isExisting: { enum: [true] },
                directorySize: {
                  type: "number",
                  title: "Content Size",
                },
                updateFile: {
                  type: "boolean",
                  title: "Update Content (SCORM 1.2)?",
                },
                created: {
                  title: "Created",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                  },
                },
                lastModified: {
                  title: "Last Modified",
                  type: "object",
                  properties: {
                    at: {
                      type: "string",
                    },
                    userName: {
                      type: "string",
                    },
                    personName: {
                      type: "string",
                    },
                  },
                },
                version: {
                  type: "number",
                  title: "Version",
                },
              },
            },
          ],
        },
        updateFile: {
          oneOf: [
            {
              properties: {
                updateFile: { enum: [false] },
              },
            },
            {
              required: ["file"],
              properties: {
                updateFile: { enum: [true] },
                file: {
                  type: "object",
                  title: "Content (SCORM 1.2)",
                  required: ["fileUploadId", "fileName", "fileSizeBytes"],
                  properties: {
                    fileUploadId: {
                      type: "string",
                    },
                    fileName: {
                      type: "string",
                    },
                    fileSizeBytes: {
                      type: "number",
                    },
                  },
                },
                isFileInvalid: {
                  type: "boolean",
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      "ui:order": ["name", "directorySize", "durationMinutes", "updateFile", "file", "*"],
      isExisting: {
        "ui:widget": "hidden",
      },
      id: {
        "ui:widget": "hidden",
      },
      name: {
        "ui:widget": "ReadOnlyWidget",
        "ui:options": {
          emptyValue: "(Pending)",
        },
      },
      updateFile: {
        "ui:widget": formMode === "read" ? "hidden" : undefined,
      },
      file: {
        "ui:field": formMode === "read" ? "HiddenField" : "FileField",
        "ui:options": {
          uploadUrl: "/api/administration/courses/file-upload",
          acceptedMimeTypes: ["application/x-zip-compressed"],
          maxSizeBytes: 500000000, // 500 MB
        },
        "ui:help": "You may upload a ZIP file that is no more than 500 MB in size.",
      },
      isFileInvalid: {
        "ui:widget": "hidden",
      },
      directorySize: {
        "ui:widget": "ReadOnlyWidget",
      },
      durationMinutes: {
        "ui:widget": formMode === "read" ? "ReadOnlyWidget" : undefined,
      },
      created: {
        "ui:field": "AuditDetailsField",
      },
      lastModified: {
        "ui:field": "AuditDetailsField",
      },
      version: {
        "ui:widget": "ReadOnlyWidget",
      },
    },
  };

  return formSchema;
};
